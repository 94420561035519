export enum LoadStateType {
  pending,
  complete,
  error,
}

export interface LoadStateDataError {
  message: string;
}

export interface LoadState {
  type: LoadStateType;
  data?: LoadStateDataError | any | null;
}

export interface LoadStatus {
  isLoading: boolean;
  type: LoadStateType;
  errorMessage?: string;
  key: string;
}
