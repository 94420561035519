import { LoadState, LoadStateType, LoadStatus } from '@core/models/load-state.model';
import { Injectable } from '@angular/core';

@Injectable()
export class LoadStateHandlerService {
  public isLoading: boolean;
  public loadingErrorMessage: string;
  public areLoading: boolean[] = [];
  private states: LoadStatus[] = [];

  public setLoadState(loadState: LoadState): void {
    let errorMessage: string;

    switch (loadState.type) {
      case LoadStateType.complete:
        this.isLoading = false;
        break;
      case LoadStateType.error:
        this.isLoading = false;
        errorMessage = loadState.data.message;

        if (errorMessage) {
          this.loadingErrorMessage = errorMessage;
        }
        break;
      case LoadStateType.pending:
        this.isLoading = true;
        break;
      default:
        break;
    }
  }

  public setPendingState(): void {
    this.setLoadState({ type: LoadStateType.pending });
  }

  public setCompleteState(): void {
    this.setLoadState({ type: LoadStateType.complete });
  }

  public getStatus(key: string): LoadState {
    const index = this.states.findIndex((state) => {
      return state.key === key;
    });

    return index > -1 ? this.states[index] : null;
  }

  public updateLoaders(): void {
    this.states.forEach((state) => {
      return (this.areLoading[state.key] = state.isLoading);
    });
  }

  public setStatus(key: string, newState: LoadState): void {
    const index = this.states.findIndex((state) => {
      return state.key === key;
    });

    if (index > -1) {
      this.states[index] = this.generateLoadStatus(key, newState);
    } else {
      this.states.push(this.generateLoadStatus(key, newState));
    }

    this.updateLoaders();
  }

  public generateLoadStatus(key: string, newState: LoadState): LoadStatus {
    return {
      isLoading: newState.type === LoadStateType.pending,
      type: newState.type,
      errorMessage: newState.type === LoadStateType.error ? newState.data.message : null,
      key,
    };
  }
}
